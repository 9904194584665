<template>
    <b-container :class="[{'mobile-view-history' : isMobileHistory}]">
        <!-- Stack the columns on mobile by making one full-width and the other half-width -->
        <b-row v-if='gameType != "DT"'>
            <b-col class="text-primary">{{ $t('game.player') }}</b-col>
            <b-col class="text-danger">{{ $t('game.banker') }}</b-col>
        </b-row>

        <b-row v-if='gameType == "DT"'>
            <b-col class="text-primary">{{ $t('game.tiger') }}</b-col>
            <b-col class="text-danger">{{ $t('game.dragon') }}</b-col>
        </b-row>


        <!-- Columns start at 50% wide on mobile and bump up to 33.3% wide on desktop -->
        <b-row v-if='gameType != "DT"'>
            <b-col v-if="playerCard" class="cardContainer">
                <div class="card-data player-card" v-for="(card, idx) in playerCard.slice().reverse()" :key="idx" :class="{tilted: idx == 0, hideCard: card == '' || card == null}">
                        <span class="card-image" :class="card | cardStyle"></span>
                </div>
            </b-col>
            <b-col v-if="bankerCard" class="cardContainer">
                <div class="card-data banker-card" v-for="(card, idx) in bankerCard" :key="idx" :class="{tilted: idx == 2, hideCard: card == '' || card == null}">
                        <span class="card-image" :class="card | cardStyle"></span>
                </div>
            </b-col>
        </b-row>

        <b-row v-if='gameType == "DT"'>
            <b-col v-if="tigerCard" class="cardContainer">
                <div class="card-data player-card" v-for="(card, idx) in tigerCard.slice().reverse()" :key="idx" :class="{tilted: idx == 0, hideCard: card == ''}">
                        <span class="card-image" :class="card | cardStyle"></span>
                </div>
            </b-col>
            <b-col v-if="dragonCard" class="cardContainer">
                <div class="card-data banker-card" v-for="(card, idx) in dragonCard" :key="idx" :class="{tilted: idx == 2, hideCard: card == ''}">
                        <span class="card-image" :class="card | cardStyle"></span>
                </div>
            </b-col>
        </b-row>

        <!-- Columns are always 50% wide, on mobile and desktop -->
        <b-row v-if='gameType != "DT"'>
            <b-col class="text-primary" style="font-weight: bold;">{{ playerScore }}</b-col>
            <b-col class="text-danger" style="font-weight: bold;">{{ bankerScore }}</b-col>
        </b-row>
        <b-row v-if='gameType == "DT"'>
            <b-col class="text-primary" style="font-weight: bold;">{{ tigerScore }}</b-col>
            <b-col class="text-danger" style="font-weight: bold;">{{ dragonScore }}</b-col>
        </b-row>
    </b-container>
</template>

<script>
export default {
    name: 'DataTable',
    data() {
        return {

        }
    },
    props: {
        card: {
            type: Array, required: true
        },
        gameType: {
            type: String, required: true
        },
        isMobileHistory: {
            type: Boolean, default: false
        }
    },
    methods: {
    },
    computed: {
        tigerScore() {
            var tigerScore = '';
            if (this.card) {
                this.card.map(function(item) {
                    if (item.type == 'tiger' && (item.score || item.score === 0)) {
                        tigerScore = item.score;
                    }
                });
            }
            return tigerScore;
        },
        dragonScore() {
            var dragonScore = '';
            if (this.card) {
                this.card.map(function(item) {
                    if (item.type == 'dragon' && (item.score || item.score === 0)) {
                        dragonScore = item.score;
                    }
                });
            }
            return dragonScore;
        },
        playerScore() {
            var playerScore = '';
            if (this.card) {
                this.card.map(function(item) {
                    if (item.type == 'player' && (item.score || item.score === 0)) {
                        playerScore = item.score;
                    }
                });
            }
            return playerScore % 10;
        },
        bankerScore() {
            var bankerScore = '';
            if (this.card) {
                this.card.map(function(item) {
                    if (item.type == 'banker' && (item.score || item.score === 0)) {
                        bankerScore = item.score;
                    }
                });
            }
            return bankerScore % 10;
        },
        playerCard() {
            var playerCard = '';
            if (this.card) {
                this.card.map(function(item) {
                    if (item.type == 'player' && item.cards) {
                        playerCard = item.cards;
                    }
                });
            }
            return playerCard;
        },
        tigerCard() {
            var tigerCard = '';
            if (this.card) {
                this.card.map(function(item) {
                    if (item.type == 'tiger' && item.cards) {
                        tigerCard = item.cards;
                    }
                });
            }
            return tigerCard;
        },
        dragonCard() {
            var dragonCard = '';
            if (this.card) {
                this.card.map(function(item) {
                    if (item.type == 'dragon' && item.cards) {
                        dragonCard = item.cards;
                    }
                });
            }
            return dragonCard;
        },
        bankerCard() {
            var bankerCard = '';
            if (this.card) {
                this.card.map(function(item) {
                    if (item.type == 'banker' && item.cards) {
                        bankerCard = item.cards;
                    }
                });
            }
            return bankerCard;
        }
    },
    filters: {
        cardStyle: function(card) {

            var cardClass = '';

            if (card != null) {
                var cardNumber = card.slice(0, 1);
                if (cardNumber == 'H' || cardNumber == 'S' || cardNumber == 'C' || cardNumber == 'D') {
                    cardClass = card.toLowerCase();
                } else {
                    switch(cardNumber.toString()) {

                        case '1': cardClass = '01'; break;
                        case 'A': cardClass = '01'; break;
                        case '2': cardClass = '02'; break;
                        case '3': cardClass = '03'; break;
                        case '4': cardClass = '04'; break;
                        case '5': cardClass = '05'; break;
                        case '6': cardClass = '06'; break;
                        case '7': cardClass = '07'; break;
                        case '8': cardClass = '08'; break;
                        case '9': cardClass = '09'; break;
                        case 'T': cardClass = '10'; break;
                        case '10': cardClass = '10'; break;
                        case 'J': cardClass = '11'; break;
                        case 'Q': cardClass = '12'; break;
                        case 'K': cardClass = '13'; break;
                    }

                    if (card.slice(0, 2) != '10') {
                        cardClass = card.slice(1,2).toString().toLowerCase() + cardClass
                    } else {
                        cardClass = card.slice(2,3).toString().toLowerCase() + '10';
                    }
                }
            }

             return cardClass;
        }
    }
}
</script>

<style scoped>

    .cardContainer {
        /*min-width: 240px;*/
    }

    .card-data {
        display: inline-block;
        margin: 0 3px;
    }

    .player-card.tilted {
        transform: rotate(-90deg);
        margin-right: 9px;
    }

    .banker-card.tilted {
        transform: rotate(90deg);
        margin-left: 9px;
    }

    .hideCard {
        display: none;
    }

    .card-image {
        display: inline-block;
        width: 50px;
        height: 74px;
        background-size: contain;
        background-repeat: no-repeat;
    }

        
    .mobile-view-history .card-image {
        width: 6vw;
        height: 8.7vw;
    }


</style>
