<!--=========================================================================================
  File Name: VideoPlayer.vue
  Description: Video Player Component
==========================================================================================-->
<template>
  <div class="content float-right">
    <ul class="pagination">
      <!--li class="pagination-item">
        <button type="button" v-on:click="onClickFirstPage()" :disabled="isInFirstPage" aria-label="Go to first page" class="pagination-button">First</button>
      </li>

      <li class="pagination-item">
        <button type="button" v-on:click="onClickPreviousPage()" :disabled="isInFirstPage" aria-label="Go to previous page" class="pagination-button">Previous</button>
      </li-->
      <li v-for="(page, key) in pages" :key="key" class="pagination-item">
        <button v-if="page.name > 0" type="button" v-on:click="onClickPage(page.name)" :disabled="page.isDisabled"  :aria-label="`Go to page number ${page.name}`" :class="{ activePage: (isPageActive(page.name)), 'pagination-button': !(isPageActive(page.name)) }">
        {{ page.name }}
        </button>
        <!-- {{isPageActive(page.name)}} -->
      </li>

      <!--li class="pagination-item">
        <button type="button" v-on:click="onClickNextPage()" :disabled="isInLastPage" aria-label="Go to next page" class="pagination-button">Next</button>
      </li>

      <li class="pagination-item">
        <button type="button" v-on:click="onClickLastPage()" :disabled="isInLastPage" aria-label="Go to last page" class="pagination-button">Last</button>
      </li-->
    </ul>
  </div>
</template>


<script>

export default {
    name: 'Pagination',
        props: {
        maxVisibleButtons: {
          type: Number,
          required: false,
          default: 100
        },
        totalPages: {
          type: Number,
          required: true
        },
        total: {
          type: Number,
          required: true
        },
        perPage: {
          type: Number,
          required: true
        },
        currentPage: {
          type: Number,
          required: true
        },
    },
      computed: {
        startPage() {
          if (this.currentPage === 1) {
            return 1;
          }

          if (this.currentPage === this.totalPages) {
            return this.totalPages - this.maxVisibleButtons + 1;
          }

          return this.currentPage - 1;

        },
        endPage() {

          return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);

        },
        pages() {
          const range = [];

          for (let i = this.startPage; i <= this.endPage; i+= 1 ) {
            range.push({
              name: i,
              isDisabled: i === this.currentPage
            });
          }

          return range;
        },
        isInFirstPage() {
          return this.currentPage === 1;
        },
        isInLastPage() {
          return this.currentPage === this.totalPages;
        },
      },
      methods: {
        onClickFirstPage() {
          this.$emit('pagechanged', 1);
        },
        onClickPreviousPage() {
          this.$emit('pagechanged', this.currentPage - 1);
        },
        onClickPage(page) {
          this.$emit('pagechanged', page);
        },
        onClickNextPage() {
          this.$emit('pagechanged', this.currentPage + 1);
        },
        onClickLastPage() {
          this.$emit('pagechanged', this.totalPages);
        },
        isPageActive(page) {
          return this.currentPage === page;
        },
      }
}
</script>
<style>
.pagination {
  list-style-type: none;
}

.pagination-item {
  display: inline-block;
}

.activePage {
  background-color: white;
  color: #96816a;
  font-weight: 400;

}

.pagination-button {
    background-color: #2d2d2d;
    color:white;
}

.pagination-button:disabled,
.pagination-button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
}

.float-right {
  float: right;
}

</style>
