<!--=========================================================================================
  File Name: BettingHistory.vue
  Description: Betting History
==========================================================================================-->
<template>
<div>
    <transition name="fade">
        <div class="modal" v-if="show && !isMobile" :style="{ display: isDisplayModal }">
            <div class="modal__backdrop" @click="closeModal()" />
            <div class="modal__dialog">
                <div class="modal_main_body">
                    <div class="modal__header">
                        <div class="header-container">
                            <span class="header-title gold-text">{{ $t('betHistory.betHistoryTitle') }}</span>
                        </div>
                        <button type="button" class="modal__close" @click="closeModal()">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                                <path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                            </svg>
                        </button>
                    </div>
                    <div class="modal__body">

                        <div class="row">
                            <div class="table-con">
                                <table class='history-table'>
                                    <th scope="col"><span class='gold-text'>{{ $t('betHistory.Game') }}</span></th>
                                    <th scope="col"><span class='gold-text'>{{ $t('betHistory.Bet') }}</span></th>
                                    <th scope="col"><span class='gold-text'>{{ $t('betHistory.BetAmount') }}</span></th>
                                    <th scope="col"><span class='gold-text'>{{ $t('betHistory.Status') }}</span></th>
                                    <th scope="col"><span class='gold-text'>{{ $t('betHistory.WinAmount') }}</span></th>
                                    <th scope="col"><span class='gold-text'>{{ $t('betHistory.Result') }}</span></th>
                                    <th scope="col"><span class='gold-text'>{{ $t('betHistory.Card') }}</span></th>
                                    <th scope="col"><span class='gold-text'>{{ $t('betHistory.BetBefore') }}</span></th>
                                    <th scope="col"><span class='gold-text'>{{ $t('betHistory.BetAfter') }}</span></th>
                                    <th scope="col"><span class='gold-text'>{{ $t('betHistory.CreatedAt') }}</span></th>
                                    <tbody v-if="showLoading" :class="['history-loading']">
                                        <tr>
                                            <td colspan="11"></td>
                                        </tr>
                                    </tbody>
                                    <tbody v-if="!showLoading">

                                        <tr v-for="(d, i) in bettings.rows" :key="i">
                                            <!-- Game & Roomno & Roomorder-->
                                            <td>
                                                <label>{{ $t( 'gameList.' + JSON.parse(d.game).game) }}</label><br>
                                                <label v-if="JSON.parse(d.game).game != 'MONEY_TRANSFER'">{{ JSON.parse(d.game).roomno }}</label><br v-if="JSON.parse(d.game).game != 'MONEY_TRANSFER'">
                                                <label v-if="JSON.parse(d.game).game == 'MONEY_TRANSFER'">{{ $t('moneyTransfer.' + JSON.parse(d.game).roomno) }}</label><br v-if="JSON.parse(d.game).game == 'MONEY_TRANSFER'">
                                                <label v-if="JSON.parse(d.game).game != 'MONEY_TRANSFER'">{{ (JSON.parse(d.game).roomorder).slice((JSON.parse(d.game).roomorder).length - 5) + $t('betHistory.round') }}</label><br v-if="JSON.parse(d.game).game != 'MONEY_TRANSFER'">
                                            </td>

                                            <!-- Bet Positions -->
                                            <td v-if="JSON.parse(d.game).game != 'MONEY_TRANSFER'">
                                                <label></label>
                                                <hr>
                                                <div v-for="(bet,i) in computeBetPosAmount(d.bet)" :key="i">
                                                    <small v-if="d.bet[0].game_type != 'DT'" :class="[bet.betposition]">{{ $t('game.' + bet.betposition) }}</small>
                                                    <small v-if="d.bet[0].game_type == 'DT'" :class="[bet.betposition]">{{ $t('game.dt' + bet.betposition) }}</small>
                                                </div>
                                            </td>

                                            <!-- Bet Amount -->
                                            <td v-if="JSON.parse(d.game).game != 'MONEY_TRANSFER'">
                                                <label :class="['total-value']">{{ getBetTotal(d.bet) | currency}}</label>
                                                <hr>
                                                <div v-for="(bet,i) in computeBetPosAmount(d.bet)" :key="i">
                                                    <small :class="[bet.betposition]">{{ bet.betamount | currency }}</small>
                                                </div>
                                            </td>

                                            <!-- Status -->
                                            <td v-if="JSON.parse(d.game).game != 'MONEY_TRANSFER'">
                                                <label></label>
                                                <hr>
                                                <div v-for="(bet, i) in computeBetStatus(d.bet)" :key="i">
                                                    <small class="text-warning" v-if="bet.state == 0">{{ $t('betHistory.UnprocessedState') }}</small>
                                                    <small class="text-danger" v-if="bet.state == 1">{{ $t('betHistory.BetWinState') }}</small>
                                                    <small class="text-primary" v-if="bet.state == 2">{{ $t('betHistory.BetLoseState') }}</small>
                                                    <small class="text-success" v-if="bet.state == 3">{{ $t('betHistory.BetTieState') }}</small>
                                                    <small class="text-warning" v-if="bet.state == 4">{{ $t('betHistory.BetRefundState') }}</small>
                                                    <small class="text-warning" v-if="bet.state == 5">{{ $t('betHistory.BetCancelState') }}</small>
                                                </div>
                                            </td>

                                            <!-- Blank Column For Money Transfer -->
                                            <td v-if="JSON.parse(d.game).game == 'MONEY_TRANSFER'" colspan="3"></td>

                                            <!-- Win Amount -->
                                            <td v-if="JSON.parse(d.game).game != 'MONEY_TRANSFER'">
                                                <label :class="['total-value', {'text-danger': getWinTotal(d.bet) > 0, 'text-primary': getWinTotal(d.bet) < 0}]">{{ (getWinTotal(d.bet)) | currency}}</label>
                                                <hr>
                                                <div v-for="(bet,i) in computeBetWinAmount(d.bet)" :key="i">
                                                    <small>{{ bet.winprice | currency }}</small>
                                                </div>
                                            </td>

                                            <!-- Money Transfer Amount -->
                                            <td v-if="JSON.parse(d.game).game == 'MONEY_TRANSFER'">
                                                <label :class="['total-value', {'text-danger': getWinTotalWithTie(d.bet) > 0, 'text-primary': getWinTotalWithTie(d.bet) < 0}]">{{ (getWinTotalWithTie(d.bet)) | currency}}</label>
                                            </td>

                                            <!-- Game Result -->
                                            <td v-if="d.bet[0].game_type == 'BULL' || d.bet[0].game_type == null">
                                                <label v-for="(res,idx) in (decodeResultBull(d.bet[0].game_result))" :key="idx" :class="['result-text-label', res.class]">{{ $t('game.' + res.text) }}</label>
                                            </td>
                                            <td v-if="d.bet[0].game_type != 'DT' && d.bet[0].game_type != 'BULL' && d.bet[0].game_type != null">
                                                <label v-for="(res,idx) in (decodeResult(d.bet[0].game_result))" :key="idx" :class="['result-text-label', res.class]">{{ $t('game.' + res.text) }}</label>
                                            </td>
                                            <td v-if="d.bet[0].game_type == 'DT'">
                                                <label v-for="(res,idx) in (decodeResultDT(d.bet[0].game_result))" :key="idx" :class="['result-text-label', res.class]">{{ $t('game.' + res.text) }}</label>
                                            </td>
                                            <!-- Card Result -->
                                            <td>
                                                <decode-card v-if="d.bet[0].game_cards != null && d.bet[0].game_cards && d.bet[0].game_cards.length != 0 && (d.bet[0].game_type != 'BULL' && d.bet[0].game_type != null)" :card="d.bet[0].game_cards ? d.bet[0].game_cards : Array()" :gameType='d.bet[0].game_type'></decode-card>
                                                <label v-if="(d.bet[0].game_type == 'BULL' || d.bet[0].game_type == null) && d.bet[0].game_cards != null && d.bet[0].game_cards" :class="['show-bull-history']" @click="showBullCardHistoryModal(d.bet[0].game_cards)">{{$t('betHistory.show')}}</label>
                                            </td>
                                            <!-- Wallet Before -->
                                            <td>
                                                <small>{{ getWalletBefore(d.bet) | currency }}</small>
                                            </td>
                                            <!-- Wallet After -->
                                            <td v-if="JSON.parse(d.game).game != 'MONEY_TRANSFER'">
                                                <small>{{ getWalletAfter(d.bet) | currency }}</small>
                                            </td>
                                            <!-- Wallet After -->
                                            <td v-if="JSON.parse(d.game).game == 'MONEY_TRANSFER'">
                                                <small>{{ getWalletAfterTransfer(d.bet) | currency }}</small>
                                            </td>
                                            <!-- Date -->
                                            <td>
                                                <small>{{ d.bet[0].createdAt | moment('YYYY.MM.DD') }}</small><br>
                                                <small>{{ d.bet[0].createdAt | moment('h:mm:ss A') }}</small>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class='page-contner'>
                                    <pagination :total-pages="bettings.pages" :total="bettings.pages" :per-page="itemPerPage" :current-page="currentPage" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal mobile-view" v-if="show && isMobile" :style="{ display: isDisplayModal }">
            <div class="modal__backdrop" @click="closeModal()" />
            <div class="modal__dialog">
                <div class="modal_main_body">
                    <div class="modal__header">
                        <div class="header-container">
                            <span class="header-title gold-text">{{ $t('betHistory.betHistoryTitle') }}</span>
                        </div>
                        <button type="button" class="modal__close" @click="closeModal()">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                                <path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                            </svg>
                        </button>
                    </div>
                    <div class="modal__body modal__body-mobile">

                        <div class="row">
                            <div class="table-con table-con-mobile">
                                <!--div class="accordion" role="tablist">
                                    <b-card no-body class="mb-1">
                                        <b-card-header header-tag="header" class="p-1" role="tab">
                                            <b-button block v-b-toggle.accordion-1 variant="info">Accordion 1</b-button>
                                        </b-card-header>
                                        <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                            <b-card-body>
                                            <b-card-text>I start opened because <code>visible</code> is <code>true</code></b-card-text>
                                            <b-card-text>123</b-card-text>
                                            </b-card-body>
                                        </b-collapse>
                                    </b-card>
                                </div-->
                                <table class='history-table'>
                                    <th scope="col"><span class='gold-text'>{{ $t('betHistory.Game') }}</span></th>
                                    <th scope="col"><span class='gold-text'>{{ $t('betHistory.BetBefore') }}</span></th>
                                    <th scope="col"><span class='gold-text'>{{ $t('betHistory.BetAfter') }}</span></th>
                                    <th scope="col"><span class='gold-text'>{{ $t('betHistory.showDetails') }}</span></th>
                                    <tbody v-if="showLoading" :class="['history-loading']">
                                        <tr>
                                            <td colspan="4"></td>
                                        </tr>
                                    </tbody>
                                    <tbody v-for="(d, i) in bettings.rows" :key="i">
                                        <tr v-if="!showLoading">
                                            <!-- Game & Roomno & Roomorder-->
                                            <td>
                                                <label>{{ $t( 'gameList.' + JSON.parse(d.game).game) }}</label><br>
                                                <label v-if="JSON.parse(d.game).game != 'MONEY_TRANSFER'">{{ JSON.parse(d.game).roomno }}</label><br v-if="JSON.parse(d.game).game != 'MONEY_TRANSFER'">
                                                <label v-if="JSON.parse(d.game).game == 'MONEY_TRANSFER'">{{ $t('moneyTransfer.' + JSON.parse(d.game).roomno) }}</label><br v-if="JSON.parse(d.game).game == 'MONEY_TRANSFER'">
                                                <label v-if="JSON.parse(d.game).game != 'MONEY_TRANSFER'">{{ (JSON.parse(d.game).roomorder).slice((JSON.parse(d.game).roomorder).length - 5) + $t('betHistory.round') }}</label><br v-if="JSON.parse(d.game).game != 'MONEY_TRANSFER'">
                                            </td>

                                            <!-- Wallet Before -->
                                            <td>
                                                <small>{{ getWalletBefore(d.bet) | currency }}</small>
                                            </td>
                                            <!-- Wallet After -->
                                            <td v-if="JSON.parse(d.game).game != 'MONEY_TRANSFER'">
                                                <small>{{ getWalletAfter(d.bet) | currency }}</small>
                                            </td>
                                            <!-- Wallet After -->
                                            <td v-if="JSON.parse(d.game).game == 'MONEY_TRANSFER'">
                                                <small>{{ getWalletAfterTransfer(d.bet) | currency }}</small>
                                            </td>
                                            <!-- Show Details -->
                                            <td>
                                                <small><b-button-group @click="showMobileRow = (showMobileRow == d.bet[0].id ? '' : d.bet[0].id)">{{ showMobileRow != d.bet[0].id ?  $t('View') : $t('Hide') }}</b-button-group></small>
                                            </td>
                                        </tr>
                                        <tr v-if="!showLoading && showMobileRow == d.bet[0].id && (d.bet[0].game_cards != null && d.bet[0].game_cards && (d.bet[0].game_type != 'BULL' && d.bet[0].game_type != null) || ((d.bet[0].game_type == 'BULL' || d.bet[0].game_type == null) && d.bet[0].game_cards != null && d.bet[0].game_cards))">
                                            <!-- Card Result -->
                                            <td colspan="4">
                                                <decode-card v-if="d.bet[0].game_cards != null && d.bet[0].game_cards && (d.bet[0].game_type != 'BULL' && d.bet[0].game_type != null)" :card="d.bet[0].game_cards ? d.bet[0].game_cards : Array()" :gameType='d.bet[0].game_type' :isMobileHistory="true"></decode-card>
                                                <label v-if="(d.bet[0].game_type == 'BULL' || d.bet[0].game_type == null) && d.bet[0].game_cards != null && d.bet[0].game_cards" :class="['show-bull-history']" @click="showBullCardHistoryModal(d.bet[0].game_cards)">{{$t('betHistory.show')}}</label>
                                            </td>
                                        </tr>
                                        <tr v-if="!showLoading && showMobileRow == d.bet[0].id">
                                            <td>
                                                <small class="gold-text">{{ $t('betHistory.Bet') }}</small>
                                            </td>
                                            <td colspan="2">
                                                <small class="gold-text">{{ $t('betHistory.BetAmount') }}</small>
                                            </td>
                                            <td>
                                                <small class="gold-text">{{ $t('betHistory.Status') }}</small>
                                            </td>
                                        </tr>
                                        <tr v-if="!showLoading && showMobileRow == d.bet[0].id">
                                            <!-- Bet Position -->
                                            <td>
                                                <div v-for="(bet,i) in computeBetPosAmount(d.bet)" :key="i">
                                                    <small v-if="d.bet[0].game_type != 'DT'" :class="[bet.betposition]">{{ $t('game.' + bet.betposition) }}</small>
                                                    <small v-if="d.bet[0].game_type == 'DT'" :class="[bet.betposition]">{{ $t('game.dt' + bet.betposition) }}</small>
                                                </div>
                                            </td>
                                            <!-- Bet Amount -->
                                            <td colspan="2">
                                                <div v-for="(bet,i) in computeBetPosAmount(d.bet)" :key="i">
                                                    <small :class="[bet.betposition]">{{ bet.betamount | currency }}</small>
                                                </div>
                                            </td>
                                            <!-- Result -->
                                            <td>
                                                <div v-for="(bet, i) in computeBetStatus(d.bet)" :key="i">
                                                    <small class="text-warning" v-if="bet.state == 0">{{ $t('betHistory.UnprocessedState') }}</small>
                                                    <small class="text-danger" v-if="bet.state == 1">{{ $t('betHistory.BetWinState') }}</small>
                                                    <small class="text-primary" v-if="bet.state == 2">{{ $t('betHistory.BetLoseState') }}</small>
                                                    <small class="text-success" v-if="bet.state == 3">{{ $t('betHistory.BetTieState') }}</small>
                                                    <small class="text-warning" v-if="bet.state == 4">{{ $t('betHistory.BetRefundState') }}</small>
                                                    <small class="text-warning" v-if="bet.state == 5">{{ $t('betHistory.BetCancelState') }}</small>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <!--b-table :items="bettings.rows" :fields="mobile_fields" striped responsive="sm">
                                    <template #cell(show_details)="row">
                                        <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                                        {{ row.detailsShowing ? 'Hide' : 'Show'}} Details
                                        </b-button>

                                        b-form-checkbox v-model="row.detailsShowing" @change="row.toggleDetails">
                                        Details via check
                                        </b-form-checkbox>
                                    </template>

                                    <template #row-details="row">
                                        <b-card>
                                        <b-row class="mb-2">
                                            <b-col sm="3" class="text-sm-right"><b>Age:</b></b-col>
                                            <b-col>{{ row.item.age }}</b-col>
                                        </b-row>

                                        <b-row class="mb-2">
                                            <b-col sm="3" class="text-sm-right"><b>Is Active:</b></b-col>
                                            <b-col>{{ row.item.isActive }}</b-col>
                                        </b-row>

                                        <b-button size="sm" @click="row.toggleDetails">Hide Details</b-button>
                                        </b-card>
                                    </template>
                                </b-table-->
                                <div class='page-contner'>
                                    <pagination :total-pages="bettings.pages" :total="bettings.pages" :per-page="itemPerPage" :current-page="currentPage" @pagechanged="onPageChange" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
    <transition name="fade">
        <div class="modal modal-lg" style="left:25%;" v-if="showBullCard" :style="{ display: isDisplayModal }">
            <div class="modal__backdrop" @click="closeBullModal()"></div>
            <div class="modal__dialog">
                <div class="modal_main_body">
                    <div class="modal__header">
                        <div class="header-container">
                            <span class="header-title gold-text">카드 게임 결과</span>
                        </div>
                        <button type="button" class="modal__close" @click="closeBullModal()">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512">
                                <path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path>
                            </svg>
                        </button>
                    </div>
                    <div class="modal__body">
                        <div class="row">
                            <div class="col-lg-11 ml-lg-auto text-center">
                                <h4><span class="banker-text-rest">BANKER</span></h4>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-2">
                                <b-col cols="2" class="card-data" v-for="(card, idx) in (bossCard)" :key="idx">
                                    <span class="card-image" :class="card | lowercase"></span>
                                </b-col>
                            </div>
                            <div class="col-lg-1"></div>
                            <div class="col-lg-9">
                                <div class="row">
                                    <div class="col-lg-2 card-data" v-for="(card, idx) in (bankerCard)" :key="idx">
                                        <span class="card-image" :class="card | lowercase"></span>
                                    </div>
                                </div>
                                <div class="row card-sum" style="padding-left:40%">
                                    <span>{{this.bankerSum}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="padding-top: 20px;">
                            <div class="col-lg-4">
                                <div class="row mrl-no">
                                    <div class="col-lg-2"></div>
                                    <div class="card-data col-lg-4" v-for="(card, idx) in (player1Card).slice(3)" :key="idx">
                                        <span class="card-image" :class="card | lowercase"></span>
                                    </div>
                                    <div class="col-lg-2"></div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="row mrl-no">
                                    <div class="col-lg-2"></div>
                                    <div class="card-data col-lg-4" v-for="(card, idx) in (player2Card).slice(3)" :key="idx">
                                        <span class="card-image" :class="card | lowercase"></span>
                                    </div>
                                    <div class="col-lg-2"></div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="row mrl-no">
                                    <div class="col-lg-2"></div>
                                    <div class="card-data col-lg-4" v-for="(card, idx) in (player3Card).slice(3)" :key="idx">
                                        <span class="card-image" :class="card | lowercase"></span>
                                    </div>
                                    <div class="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4">
                                <div class="row mrl-no">
                                    <div class="card-data col-lg-4" v-for="(card, idx) in (player1Card).slice(0,3)" :key="idx">
                                        <span class="card-image" :class="card | lowercase"></span>
                                    </div>
                                </div>
                                <div class="row card-sum">
                                    <span>{{this.player1Sum}}</span>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 text-center">
                                        <h4><span class="player-text-rest">PLAYER 1</span></h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="row mrl-no">
                                    <div class="card-data col-lg-4" v-for="(card, idx) in (player2Card).slice(0,3)" :key="idx">
                                        <span class="card-image" :class="card | lowercase"></span>
                                    </div>
                                </div>
                                <div class="row card-sum">
                                    <span>{{this.player2Sum}}</span>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 text-center">
                                        <h4><span class="player-text-rest">PLAYER 2</span></h4>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="row mrl-no">
                                    <div class="card-data col-lg-4" v-for="(card, idx) in (player3Card).slice(0,3)" :key="idx">
                                        <span class="card-image" :class="card | lowercase"></span>
                                    </div>
                                </div>
                                <div class="row card-sum">
                                    <span>{{this.player3Sum}}</span>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 text-center">
                                        <h4><span class="player-text-rest">PLAYER 3</span></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</div>
</template>

<script>
import Pagination from '@/components/baccarat/game/Pagination'
import DecodeCard from '@/views/common/pc/DecodeCard';
export default {
    name: 'BettingHistory',
    components: {
        Pagination,
        DecodeCard
    },
    mounted() {
        // this.getAgentsList()
        // this.getGames()
        //this.getBettings()
    },
    data() {
        return {
            showMobileRow: '',
            currentPage: 1,
            itemPerPage: 50,
            isDisplayModal: '',
            show: false,
            showLoading: false,
            showBullCard: false,
            bettings: {
                count: 0,
                pages: 0,
                rows: []
            },
            slots: [{
                name: 'bet_user'
            }, {
                name: 'game'
            }, {
                name: 'betposition'
            }, {
                name: 'betamount'
            }, {
                name: 'state'
            }, {
                name: 'winprice'
            }, {
                name: 'game_result'
            }, {
                name: 'game_cards'
            }, {
                name: 'createdAt'
            }],
            agentList: [],
            games: [],
            gameRoomno: [],
            agentSelected: 'ALL',
            memberSelected: '',
            gameSelected: 'ALL',
            gameRoomnoSelected: 'ALL',
            roomorderSelected: '',
            start_date: new Date().toISOString().slice(0, 10),
            end_date: new Date().toISOString().slice(0, 10),
            player1Card: ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'],
            player2Card: ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'],
            player3Card: ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'],
            bankerCard: ['ZZ', 'ZZ', 'ZZ', 'ZZ', 'ZZ'],
            bossCard: ['ZZ'],
            bankerSum: 0,
            player1Sum: 0,
            player2Sum: 0,
            player3Sum: 0,
        }
    },
    props: {
        // list: {required: true, type: Object},
    },
    computed: {
        isMobile() {
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                return true
            } else {
                return false
            }
        },
        allowDates() {
            const now = new Date()
            const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
            // two months prior
            const minDate = new Date(today)
            minDate.setMonth(minDate.getMonth() - 2)
            minDate.setDate(1)

            return {
                min: minDate,
                max: today
            }
        }
    },
    watch: {

        // gameSelected: {
        //     handler(val) {
        //         this.getRoomno(val)
        //     },
        //     immediate: true
        // }
    },
    methods: {
        onPageChange(page) {
            this.currentPage = page;
            this.getBettings();
        },
        closeModal() {
            this.show = false;
        },
        openModal() {
            this.show = true;
            this.currentPage = 1;
            this.getBettings();
        },
        showBullCardHistoryModal(cardData) {
            console.log(cardData);
            console.log('popup modal for card data');
            this.showBullCard = true;

            var bossCardData = cardData.boss;
            var bankerCardsData = cardData.banker.highcards;
            var player1CardsData = cardData.player1.highcards;
            var player2CardsData = cardData.player2.highcards;
            var player3CardsData = cardData.player3.highcards;

            if (bossCardData.length > 0) {
                let x = 0;
                bossCardData.forEach((key, item) => {
                    this.bossCard[x] = bossCardData[item];
                    x++;
                })
            }

            if (bankerCardsData.length > 0) {
                let x = 0;
                bankerCardsData.forEach((key, item) => {
                    this.bankerCard[x] = bankerCardsData[item][0]
                    x++;
                })
                this.bankerSum = cardData.banker.sum;
            }

            if (player1CardsData.length > 0) {
                let x = 0;
                player1CardsData.forEach((key, item) => {
                    this.player1Card[x] = player1CardsData[item][0]
                    x++;
                })
                this.player1Sum = cardData.player1.sum;
            }

            if (player2CardsData.length > 0) {
                let x = 0;
                player2CardsData.forEach((key, item) => {
                    this.player2Card[x] = player2CardsData[item][0]
                    x++;
                })
                this.player2Sum = cardData.player2.sum;
            }

            if (player3CardsData.length > 0) {
                let x = 0;
                player3CardsData.forEach((key, item) => {
                    this.player3Card[x] = player3CardsData[item][0]
                    x++;
                })
                this.player3Sum = cardData.player3.sum;
            }

            //var cardBullData = cardData;
            /*cardBullData.forEach((key, item) => {
                console.log(cardBullData[item]);
                if (cardBullData[item].type == 'boss') {
                    this.bossCard = cardBullData[item].cards;
                } else if (cardBullData[item].type == 'banker') {
                    this.bankerCard = cardBullData[item].cards;
                    let x = 1;
                    while (this.bankerCard.length < 5) {
                        this.bankerCard[x] = 'ZZ';
                        x++;
                    }
                } else if (cardBullData[item].type == 'player1') {
                    this.player1Card = cardBullData[item].cards;
                    let x = 1;
                    while (this.player1Card.length < 5) {
                        this.player1Card[x] = 'ZZ';
                        x++;
                    }
                } else if (cardBullData[item].type == 'player2') {
                    this.player2Card = cardBullData[item].cards;
                    let x = 1;
                    while (this.player2Card.length < 5) {
                        this.player2Card[x] = 'ZZ';
                        x++;
                    }
                } else if (cardBullData[item].type == 'player3') {
                    this.player3Card = cardBullData[item].cards;
                    let x = 1;
                    while (this.player3Card.length < 5) {
                        this.player3Card[x] = 'ZZ';
                        x++;
                    }
                }
            });*/
        },
        closeBullModal() {
            this.showBullCard = false;
        },
        decodeResultDT(res) {

            let val = ''
            const tiger = {
                class: 'label-tiger-result',
                text: 'tiger'
            }
            const dragon = {
                class: 'label-dragon-result',
                text: 'dragon'
            }
            const tie = {
                class: 'label-tie-result',
                text: 'tie'
            }

            switch (res) {
                case 'a':
                    val = [dragon];
                    break;
                case 'e':
                    val = [tiger];
                    break;
                case 'i':
                    val = [tie];
                    break;
            }

            return res ? val : []
        },
        decodeResultBull(res) {
            let val = [];
            let results = [];
            if (res) {
                results = res.split(',');
            }
            const p1 = {
                class: 'label-player-result',
                text: 'p1'
            }
            const p2 = {
                class: 'label-player-result',
                text: 'p2'
            }
            const p3 = {
                class: 'label-player-result',
                text: 'p3'
            }
            const b1 = {
                class: 'label-banker-result',
                text: 'b1'
            }
            const b2 = {
                class: 'label-banker-result',
                text: 'b2'
            }
            const b3 = {
                class: 'label-banker-result',
                text: 'b3'
            }
            const p1b = {
                class: 'label-player-result',
                text: 'p1b'
            }
            const p2b = {
                class: 'label-player-result',
                text: 'p2b'
            }
            const p3b = {
                class: 'label-player-result',
                text: 'p3b'
            }
            const b1b = {
                class: 'label-banker-result',
                text: 'b1b'
            }
            const b2b = {
                class: 'label-banker-result',
                text: 'b2b'
            }
            const b3b = {
                class: 'label-banker-result',
                text: 'b3b'
            }
            results.forEach((key, item) => {
                switch (results[item]) {
                    case 'p1':
                        val.push(p1);
                        break;
                    case 'p2':
                        val.push(p2);
                        break;
                    case 'p3':
                        val.push(p3);
                        break;
                    case 'b1':
                        val.push(b1);
                        break;
                    case 'b2':
                        val.push(b2);
                        break;
                    case 'b3':
                        val.push(b3);
                        break;
                    case 'p1b':
                        val.push(p1b);
                        break;
                    case 'p2b':
                        val.push(p2b);
                        break;
                    case 'p3b':
                        val.push(p3b);
                        break;
                    case 'b1b':
                        val.push(b1b);
                        break;
                    case 'b2b':
                        val.push(b2b);
                        break;
                    case 'b3b':
                        val.push(b3b);
                        break;
                }
            });
            return val;
        },
        decodeResult(res) {

            let val = ''

            const banker = {
                class: 'label-banker-result',
                text: 'banker'
            }
            const bankerPair = {
                class: 'label-banker-pair-result',
                text: 'bankerPair'
            }
            const player = {
                class: 'label-player-result',
                text: 'player'
            }
            const playerPair = {
                class: 'label-player-pair-result',
                text: 'playerPair'
            }
            const tie = {
                class: 'label-tie-result',
                text: 'tie'
            }

            switch (res) {
                case 'a':
                    val = [banker];
                    break;
                case 'b':
                    val = [banker, playerPair];
                    break;
                case 'c':
                    val = [banker, bankerPair];
                    break;
                case 'd':
                    val = [banker, playerPair, bankerPair];
                    break;
                case 'e':
                    val = [player];
                    break;
                case 'f':
                    val = [player, playerPair];
                    break;
                case 'g':
                    val = [player, bankerPair];
                    break;
                case 'h':
                    val = [player, playerPair, bankerPair];
                    break;
                case 'i':
                    val = [tie];
                    break;
                case 'j':
                    val = [tie, playerPair];
                    break;
                case 'k':
                    val = [tie, bankerPair];
                    break;
                case 'l':
                    val = [tie, playerPair, bankerPair];
                    break;
            }

            return res ? val : []
        },
        // async getAgentsList() {
        //     this.agentList = await window.$ax.get(`/agents/list/ALL`).then(res => res.data).catch(() => [])
        // },
        // async getGames() {
        //     await window.$ax.get('/games/active/list').then(res => {
        //         this.games = res.data, this.gameSelected = res.data[0].code
        //     })
        //     await this.getRoomno(this.gameSelected)
        // },
        // async getRoomno(gameCode) {
        //     await window.$ax.post('/games/roomno/list', {gameCode}).then(res => {
        //         this.gameRoomno = res.data, this.gameRoomnoSelected = res.data[0].roomno
        //     })
        // },
        async getBettings() {

            this.showLoading = true;

            const token = this.$cookies.get('game_session')
            this.bettings = await window.$ax.get(`https://gm.defensilx918.com/api/history/${token}/${this.currentPage}`).then(res => res.data).catch(() => []);

            this.showMobileRow = '';

            this.showLoading = false;
        },
        computeBetPosAmount(bets) { // betting position amount
            //return Array.from(bets.reduce((m, {betposition, betamount}) => m.set(betposition, (m.get(betposition) || 0) + parseInt(betamount)), new Map), ([betposition, betamount]) => ({betposition, betamount}))
            let returnArray = Array.from(bets.reduce((m, {
                betposition,
                betamount
            }) => m.set(betposition, (m.get(betposition) || 0) + parseInt(betamount)), new Map), ([betposition, betamount]) => ({
                betposition,
                betamount
            }))
            returnArray = returnArray.sort((a, b) => (a.betposition).localeCompare(b.betposition));
            return returnArray;
        },
        computeBetWinAmount(bets) { // winning amount
            //return Array.from(bets.reduce((m, {betposition, wallet_after, wallet_before, betamount}) => m.set(betposition, (m.get(betposition) || 0) + (parseInt(wallet_after - wallet_before > 0 ? wallet_after - wallet_before - betamount : 0))), new Map), ([betposition, winprice]) => ({betposition, winprice}))
            let returnArray = Array.from(bets.reduce((m, {
                    betposition,
                    wallet_after,
                    wallet_before,
                    betamount,
                    state
                }) => m.set(betposition,
                    (m.get(betposition) || 0) + (parseInt(wallet_after - wallet_before > 0 ? wallet_after - wallet_before - betamount : state == 0 ? 0 : -(betamount)))),
                new Map), ([betposition, winprice]) => ({
                betposition,
                winprice
            }))
            returnArray = returnArray.sort((a, b) => (a.betposition).localeCompare(b.betposition));
            return returnArray;
        },
        computeBetStatus(bets) {
            //return Array.from(bets.reduce((m, {betposition, state, wallet_after, wallet_before, betamount}) => m.set(betposition, (m.get(betposition) || 0) + state == 0 ? state : wallet_after - wallet_before == parseInt(betamount) ? 3 : wallet_after - wallet_before > 0 ? 1 : 2), new Map), ([betposition, state]) => ({betposition, state}))
            let returnArray = Array.from(bets.reduce((m, {
                betposition,
                state,
                wallet_after,
                wallet_before,
                state_result,
                betamount
            }) => m.set(betposition, (m.get(betposition) || 0) + state == 0 ? state : state_result == 'q' && state == 1 ? 5 :  (wallet_after - wallet_before == parseInt(betamount)) && state_result == 'q' ? 4 : wallet_after - wallet_before == parseInt(betamount) ? 3 : wallet_after - wallet_before > 0 ? 1 : 2), new Map), ([betposition, state]) => ({
                betposition,
                state
            }))
            returnArray = returnArray.sort((a, b) => (a.betposition).localeCompare(b.betposition));
            return returnArray;
        },
        computeTotal(bets, key) {
            return bets.reduce((a, b) => a + parseInt(b[key] || 0), 0)
        },
        getBetTotal(bets) {
            let total = 0;
            bets.forEach((key, item) => {
                total += (parseInt(bets[item].betamount));
            });
            return total;
        },
        getWinTotal(bets) {
            let total = 0,
                winamount = 0;
            bets.forEach((key, item) => {
                if (bets[item].state == 0) {
                    return 0;
                }
                winamount = parseInt(bets[item].wallet_after) - parseInt(bets[item].wallet_before);
                if (winamount > 0) {
                    total += winamount - bets[item].betamount;
                } else {
                    total -= bets[item].betamount;
                }
            });

            return total;
        },
        getWinTotalWithTie(bets) {
            let total = 0,
                winamount = 0;
            bets.forEach((key, item) => {
                if (bets[item].state == 0) {
                    return 0;
                }
                winamount = parseInt(bets[item].wallet_after) - parseInt(bets[item].wallet_before);
                if (winamount > 0) {
                    total += winamount;
                } else {
                    total -= bets[item].betamount;
                }
            });

            return total;
        },
        getWalletBefore(bets) {
            let returnamount = 0;
            bets.forEach((key, item) => {
                if (item == 0) {
                    returnamount = parseInt(bets[item].wallet_before);
                }
                if (returnamount < parseInt(bets[item].wallet_before)) {
                    returnamount = parseInt(bets[item].wallet_before);
                }
            });
            return returnamount;
        },
        getWalletAfter(bets) {
            let returnamount = 0,
                winTotal = 0;
            winTotal = this.getWinTotal(bets);
            bets.forEach((key, item) => {
                if (item == 0) {
                    returnamount = parseInt(bets[item].wallet_before) + winTotal;
                }
                if (returnamount < parseInt(bets[item].wallet_before) + winTotal) {
                    returnamount = parseInt(bets[item].wallet_before) + winTotal;
                }
            });
            return returnamount;
        },
        getWalletAfterTransfer(bets) {
            let returnamount = 0;
            bets.forEach((key, item) => {
                returnamount = parseInt(bets[item].wallet_after);
            });
            return returnamount;
        }
    },
    filters: {
        lowercase: function (str) {
            return str.toString().toLowerCase();
        },
    }
}
</script>

<style lang="scss" scoped>
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    overflow-x: hidden;
    overflow-y: auto;
    color: black;
    display: block;

    &__backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
    }

    &__dialog {
        position: relative;
        width: 85%;
        height: 85%;
        background-color: transparent;
        border-radius: 5px;
        margin: 50px auto;
        display: flex;
        flex-direction: column;
        z-index: 99999;

        @media screen and (max-width: 992px) {
            width: 90%;
        }
    }

    &__close {
        width: 30px;
        height: 30px;
    }

    &__header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 20px 20px 10px;
    }

    &__body {
        padding: 10px 20px 10px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        margin: 0 2% 2% 2%;
        color: white;
        background-color: black;
        height: 85%;
        width: 96%;
        top: 12%;
        position: absolute;
        border-radius: 15px;
    }

    &__footer {
        padding: 10px 20px 20px;
    }
}

.mobile-view .modal {
    &__dialog {
        width: 100%;
        margin: 0;
        height: 100%;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.modal_main_body {
    height: 100%;
    width: 100%;
    background: rgba(63, 59, 58, 0.95);
    border-radius: 10px;
}

.header-title {
    font-size: 4.5vh;
    font-weight: bold;
    top: 1.5vh;
}

/* Reset Select */
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    outline: 0;
    box-shadow: none;
    border: 0 !important;
    background: #282725;
    background-image: none;
    color: #917e63;
}

/* Remove IE arrow */
select::-ms-expand {
    display: none;
}

/* Custom Select */
.select {
    position: relative;
    display: flex;
    width: 11em;
    height: 2.1em;
    background: #282725;
    overflow: hidden;
    border-radius: 0.25em;
    font-size: 1.7vh;
}

select {
    flex: 1;
    padding: 0 .5em;
    color: #96816a;
    cursor: pointer;
}

/* Arrow */
.select::after {
    // content: '\25BC';
    content: "▼";
    position: absolute;
    top: 0.29em;
    right: 5px;
    padding: 0px 4px;
    background: #433f3e;
    cursor: pointer;
    pointer-events: none;
    transition: 0.25s all ease;
    border: 1px #433f3e solid;
    border-radius: 2px;

}

/* Transition */
.select:hover::after {
    color: #d4ae81;
}

.history-table,
.table-con {
    width: 100%;
}

.table-con.table-con-mobile {
    width: 95%;
    padding: 2.5%;
}

.modal__body.modal__body-mobile {
    padding-left: 0;
    padding-right: 0;
}

.history-table th {
    background-color: #2d2d2d;
    height: 4vh;
    font-size: 1.7vh;
    vertical-align: middle;
    text-align: center;
    border: 1px #282828 solid
}

.history-table td {
    // background-color: #2d2d2d;
    height: 4vh;
    font-size: 1.7vh;
    vertical-align: middle;
    text-align: center;
    border: 1px #282828 solid
}

.mobile-view .history-table th {
    font-size: 3.5vw;
}

.mobile-view .history-table td {
   font-size: 3.5vw;
}

.btn-4,
.btn-5 {
    height: 2.1em;
    width: 7.3em;
    background: rgb(66, 60, 60);
    background: linear-gradient(180deg, rgba(66, 60, 60, 1) 22%, rgba(48, 44, 43, 1) 75%);
    border: 1px #302c2b solid;
    border-radius: 3px;
    display: inline-block;
    margin-left: 15px;
    font-size: 1.7vh;
    color: white;
}

.btn-4:active,
.btn-4:focus,
.btn-5:active,
.btn-5:focus {
    height: 2.1em;
    width: 7.3em;
    background: rgb(229, 192, 149);
    background: linear-gradient(180deg, rgba(229, 192, 149, 1) 22%, rgba(195, 152, 104, 1) 75%);
    border: 1px #79624b solid;
    border-radius: 5px;
    display: inline-block;
    margin-left: 15px;
    font-size: 1.7vh;
}

.bottom-space {
    padding-bottom: 13px;
}

.cont-one {
    display: inline-block;
    margin-left: 15px;
    font-size: 1.7vh;
}

.cont-two {
    width: auto;
    position: absolute;
    right: 0;
}

.table-con {
    padding: 11px;
    border: 2px #272624 solid;
    margin: 8px;
    height: 100%;
}

.header-container {
    width: 100%;
    text-align: center;
}

.page-contner {
    padding-top: 20px;
    width: 100%;
}

.result-text-label {
    display: block;
}

.total-value {
    font-weight: 700;
}

.label-banker-result,
.label-banker-pair-result,
.banker,
.banker_pair,
.label-dragon-result {
    color: #dc3545;
}

.label-player-result,
.label-player-pair-result,
.player,
.player_pair,
.label-tiger-result {
    color: #007bff;
}

.label-tie-result,
.tie {
    color: #01ce00;
}

/* bull */

.show-bull-history {
    cursor: pointer;
}

.history-loading {
    background-image: url('/assets/images/game/history/loading.gif');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 3vw 3vw;
}

.history-loading td {
    height: 10vh;
}

.card-image {
    width: 3vw !important;
    height: 4.5vw !important;
    display: inline-block;
    background-size: cover;
}

.banker-text-rest {
    font-weight: 900;
    background: -webkit-linear-gradient(#dea0a0, #f00);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.player-text-rest {
    font-weight: 900;
    background: -webkit-linear-gradient(#a5b2f1, #007eff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.mrl-no {
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.card-sum {
    position: relative;
    padding-left: 50%;
    font-size: 35px;
    font-weight: bold;
}

@media (min-width: 1024px) {

    .modal-lg,
    .modal-xl {
        max-width: 900px;
    }
}
</style>
